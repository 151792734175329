<template>
  <div class="container-fluid mt-100">
    <div class="rounded py-5" style="background: url('images/insurance/cta.jpg') center center;">
      <div class="container py-md-5 py-3">
        <div class="row">
          <div class="col-lg-6 col-md-7 col-12 offset-lg-6 offset-md-5">
            <div class="card border-0">
              <div class="card-body p-md-5 p-4 bg-white rounded">
                <div class="section-title">
                  <SmartLabel tag="h4" className="title mb-4" target="title" v-model="content.title"/>
                  <SmartLabel tag="p" className="text-muted para-desc mb-0" target="subtitle" v-model="content.subtitle"/>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12 mt-4"
                    v-for="(item, key) in content.items"
                    v-bind:key="key">
                    <div class="media align-items-center">
                      <div class="icon text-center rounded-circle h4 text-primary mr-2 mb-0">
                        <i v-bind:class="`uil uil-${item.icon}`"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="title text-dark mb-0">{{item.text}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <a href="javascript:void(0);" class="btn btn-primary">{{content.callToAction}} <arrow-right-icon
                      class="fea icon-sm"></arrow-right-icon></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ArrowRightIcon, } from "vue-feather-icons";
  export default {
    name: "Advantages",
    components: {
      ArrowRightIcon,
    },
  };
</script>